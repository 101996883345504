import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  backdrop: {
    zIndex: 9999,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
  }
});

class AlertDialog extends React.Component {
  handleClose(index) {
    const { dialog } = this.props;
    dialog.handler(index);
  }

  renderButton(button, index) {
    if (typeof button === 'string') {
      return (
        <Button onClick={() => this.handleClose(index)} color="primary" key={button}>
          {button}
        </Button>
      );
    } else if (typeof button === 'object') {
      return (
        <Button
          onClick={() => this.handleClose(index)}
          color="primary"
          key={button.text}
          disabled={button.disabled || false}
        >
          {button.text}
        </Button>
      );
    }
    return null;
  }

  render() {
    const { dialog, classes } = this.props;
    return (
      <>
        <Dialog
          open={dialog.open}
          onClose={() => this.handleClose(-1)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          scroll={dialog.scroll}
          fullWidth={dialog.fullWidth}
          maxWidth={dialog.maxWidth}
          className={dialog.className}
          disableBackdropClick={dialog.disableBackdropClick}
          disableEscapeKeyDown={dialog.disableEscapeKeyDown}
        >
          <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialog.text}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {
              dialog.buttons.map((button, i) => this.renderButton(button, i))
            }
          </DialogActions>
        </Dialog>
        {dialog.loading &&
          <Backdrop
            className={classes.backdrop}
            open={true}
          >
            <CircularProgress color="inherit" />
            {dialog.loadingText && <Typography variant="subtitle1">{dialog.loadingText}</Typography>}
          </Backdrop>
        }
      </>
    );
  }
}

AlertDialog.defaultProps = {
  dialog: {
    handler: () => { },
    text: '',
    title: '',
    buttons: [],
    open: false,
    loading: false,
    fullWidth: false
  },
};

AlertDialog.propTypes = {
  dialog: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    handler: PropTypes.func,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.string,
    buttons: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          disabled: PropTypes.bool
        })
      ])
    ),
    scroll: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    className: PropTypes.string,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    loading: PropTypes.bool,
    loadingText: PropTypes.string
  }),
};

export default withStyles(styles)(AlertDialog);
