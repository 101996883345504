import React ,  {useRef}from 'react';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import { withStyles } from '@material-ui/core/styles';
import { ExportToCsv } from 'export-to-csv';
import { error, withStylesPropTypes } from '../helper/misc';
import ALIAPI from '../service/aliapi';
import adminapi from '../service/adminapi';
import UploadFile from './uploadfile';
import AlertDialog from '../components/alertdialog';

const useStyles = ((theme) => ({
  root: {
  },
  log: {
    marginTop: theme.spacing(3),
  },
}));

class AdminMisc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0,
      uploadCopyPolygon: false,
      uploadTransferPolygon: false,
      copyPolygon: null,
      transferPolygon: null,
      alert: {
        open: false,
        title: '',
        text: '',
        buttons: [],
      },
    };
    this.copyPolygonFileRef = React.createRef();
    this.transferPolygonFileRef = React.createRef();
  }

  handleDownloadAliWithParent() {
    this.setState({
      loading: true,
      total: 0,
      loaded: 0,
    });

    const records = [];

    const fnAllLoaded = () => {
      this.setState({ loading: false });

      const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useKeysAsHeaders: true,
        filename: 'ali-with-parents',
      };

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(records);
    };

    const fnFetchPage = (page) => {
      ALIAPI.getWithParentList(page)
        .then((data) => {
          records.push(...data.docs.map((doc) => ({
            Category: doc.category || '',
            ListID: doc.listID || '',
            ListName: doc.listName || '',
            ALI: doc.ali || '',
            StoreName: doc.storeName || '',
            City: doc.city || '',
            State: doc.state || '',
            ZIP: doc.zip || '',
            SquareFootage: doc.squareFootage || '',
            ParentAli: doc.parent.ali || '',
            ParentStoreName: doc.parent.storeName || '',
            ParentListName: doc.parent.listName || '',
            ParentSquareFootage: doc.parent.squareFootage ? Math.round(doc.parent.squareFootage) : '',
          })));
          this.setState({
            total: data.total,
            loaded: records.length,
          });
          if (data.offset + data.limit < data.total) fnFetchPage(page + 1);
          else fnAllLoaded();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    };
    fnFetchPage(0);
  }

  handleUploadCopyPolygon(e) {
    const selectedFile = e.target.files[0]
    const fnUploadCopyPolygon = () => {
      adminapi.uploadPolygons(selectedFile, 'copy')
      .then(data => {
        this.showAlert('Copy Polygon', 'Upload Successful', ["OK"])
      })
      .catch(error => {
        this.showError(error)
      })

     };
    fnUploadCopyPolygon(0);
  }


  handleUploadTransferPolygon(e) {
    const selectedFile = e.target.files[0]
    const fnUploadTransferPolygon = () => {
      adminapi.uploadPolygons(selectedFile, 'transfer')
      .then(data => {
        this.showAlert('Transfer Polygon', 'Upload Successful', ["OK"])
      })
      .catch(error => {
        this.showError(error)
      })

     };
    fnUploadTransferPolygon();
  }


  showAlert(title, text, buttons, handler) {
    const me = this;
    const { alert } = this.state;

    this.setState({
      alert: {
        title,
        text,
        buttons,
        open: true,
        handler: (index) => {
          me.setState({ alert: { ...alert, open: false } });
          (handler || (() => {}))(index);
        },
      },
    });
  }

  showError(err) {
    this.showAlert('Error', err.message || 'Internal Error', ['OK']);
  }

  render() {
    const { classes } = this.props;
    const { loading, total, loaded, alert } = this.state;
    return (
      <div className={classes.root}>
        <Button color="primary" variant="text" startIcon={<CloudDownloadIcon />} onClick={() => this.handleDownloadAliWithParent()} disabled={loading}>Download ALI with parents</Button>
        <div className={classes.log}>
          { loading && (
            <>
              <CircularProgress size="1em" />
              { `Downloading: ${loaded} of ${(total > 0 ? total : '...')}` }
            </>
          )}
        </div>
        <Button color="primary" variant="text" startIcon={<CloudUploadIcon />} onClick={()=>this.copyPolygonFileRef.current.click()} disabled={loading}>Upload Copy Polygon</Button>
        <div className={classes.log}><input onChange={(e)=>this.handleUploadCopyPolygon(e)} accept=".csv" multiple={false} ref={this.copyPolygonFileRef} type='file'hidden/></div>
        <Button color="primary" variant="text" startIcon={<CloudUploadIcon />} onClick={()=>this.transferPolygonFileRef.current.click()} disabled={loading}>Upload Transfer Polygon</Button>
        <div className={classes.log}><input onChange={(e)=>this.handleUploadTransferPolygon(e)} accept=".csv" multiple={false} ref={this.transferPolygonFileRef} type='file'hidden/></div>
        <AlertDialog dialog={alert} />
      </div>
    );
  }
}

AdminMisc.propTypes = {
  ...withStylesPropTypes,
};

export default withStyles(useStyles)(AdminMisc);
