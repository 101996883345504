import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

function CustomSnackbar({ open, message, severity, duration, onClose, anchorOrigin }) {
  const [openBar, setOpenBar] = React.useState(open);

  useEffect(() => {
    setOpenBar(open);
  }, [open])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenBar(false);
    onClose && onClose();
  };

  return (
    <Snackbar
      open={openBar}
      autoHideDuration={duration || 6000}
      onClose={handleClose}
      anchorOrigin={anchorOrigin || { vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        onClose={handleClose}
        severity={severity || 'info'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {message || 'This is an Alert inside a Snackbar!'}
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.defaultProps = {
  open: false,
  message: 'This is a message!',
  severity: 'info',
  duration: 6000
};

export default CustomSnackbar;
