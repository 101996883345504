import React, { Component } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import debounce from 'lodash/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';

class CustomDropdown extends Component {
    state = {
        options: [],
        selectedOption: null,
        query: '',
        page: 0,
        loading: false,
        hasMore: true,
    };

    componentDidMount() {
        this.fetchOptions(this.state.query, this.state.page);
    }

    fetchOptions = debounce(async (query, page) => {
        console.log("fetch:", query)
        const { apiEndpoint } = this.props;
        if (this.state.loading) return;

        this.setState({ loading: true });

        try {
            const response = await apiEndpoint(page, query);

            console.log("res:", response)

            this.setState((prevState) => ({
                options: page === 0 ? response.docs : [...prevState.options, ...response.docs],
                loading: false,
                hasMore: response.docs.length > 0,
            }));
        } catch (error) {
            console.error('Error fetching dropdown options:', error);
            this.setState({ loading: false });
        }
    }, 300);

    componentDidUpdate(prevProps, prevState) {
        if (prevState.query !== this.state.query) {
            this.setState({ options: [], page: 0, hasMore: true }, () => {
                this.fetchOptions(this.state.query, 0);
            });
        }
    }

    handleInputChange = (event, value, reason) => {
        // this.setState({ query: value });
        if (reason === 'input') {
            this.setState({ query: value, page: 0 }, () => {
                this.fetchOptions(this.state.query, this.state.page);
            });
        }
    };

    handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        if (bottom && this.state.hasMore && !this.state.loading) {
            this.setState(
                (prevState) => ({ page: prevState.page + 1 }),
                () => {
                    this.fetchOptions(this.state.query, this.state.page);
                }
            );
        }
    };

    handleSearchChange = (event) => {
        this.setState({ query: event.target.value });
    };

    render() {
        const { label, searchLabel, onChange, optionLabelKey = 'label', valueKey = 'value', dropDownClassName } = this.props;

        return (
            <div className={dropDownClassName}>
                <Autocomplete
                    options={this.state.options}
                    getOptionLabel={(option) => option[optionLabelKey]}
                    // filterOptions={(x) => x} // Disable client-side filtering
                    onInputChange={this.handleInputChange}
                    onChange={(event, newValue) => {
                        this.setState({ selectedOption: newValue })
                        onChange && onChange(newValue)
                    }}
                    autoHighlight
                    freeSolo
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {this.state.loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    ListboxProps={{
                        onScroll: this.handleScroll,
                    }}
                />
            </div>
        );
    }
}

export default CustomDropdown;
